import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layout/BasicPage'
import Link from '../../components/Link'
import Section from '../../components/Section'
import SEO from '../../components/SEO'
import CMS from '../../components/CMS'

import './press.scss'

const Press = ({ data, location }) => {
	const { press } = data.releases
	const { forms } = data.posters

	return (
		<Layout>
			<SEO title='Press' href={location.href} />
			<Section
				className='news'
				title='Ordaōs in the News'
				subtitle='We are in the news because we’re making huge strides in human-enabled, machine-driven drug design.'
			>
				<ul>
					{press &&
						press.map(({ result }) => {
							return isValidURL(result.article.data.url) ? (
								<PressArticle
									title={result.article.data.title}
									date={result.article.data.date}
									description={
										result.article.data.description
									}
									href={result.article.data.url}
								/>
							) : (
								<PressArticle
									title={result.article.data.title}
									date={result.article.data.date}
									description={
										result.article.data.description
									}
									to={`/press/${result.link.replace(
										/\.md$/,
										''
									)}`}
								/>
							)
						})}
				</ul>
			</Section>
			<Section
				className='news'
				title='Posters'
				subtitle='Read abstracts and sign up to download more info'
			>
				<ul>
					{forms &&
						forms.map(({ poster }) => {
							return (
								<li className='poster'>
									<Link
										to={`${poster.link.replace(
											/\.md$/,
											''
										)}`}
									>
										{poster.childMarkdownRemark.data.title}
									</Link>
									<CMS.Image
										{...poster.childMarkdownRemark.data
											.photo}
									/>
								</li>
							)
						})}
				</ul>
			</Section>
		</Layout>
	)
}

export default Press

function isValidURL(url) {
	try {
		new URL(url)
		return true
	} catch (error) {
		return false
	}
}

const PressArticle = ({ title, date, href, to }) => {
	// console.log(title, to, href)
	return (
		<li className='release' key={href ?? to}>
			<span className='release-date'>
				{new Date(date).toLocaleDateString()}
			</span>
			{isValidURL(href) ? (
				<Link href={href} rel='noopener noreferrer' target='_blank'>
					{title}
				</Link>
			) : (
				<Link to={to}>{title}</Link>
			)}
		</li>
	)
}

export const query = graphql`
	query PressQuery {
		posters: allFile(
			filter: {
				sourceInstanceName: { eq: "lead-posters" }
				extension: { eq: "md" }
			}
		) {
			forms: edges {
				poster: node {
					link: relativePath
					childMarkdownRemark {
						data: frontmatter {
							title
							description
							photo {
								...CMSImageFragment
							}
						}
					}
				}
			}
		}
		releases: allFile(
			filter: {
				sourceInstanceName: { eq: "press" }
				extension: { eq: "md" }
			}
			sort: {
				fields: childrenMarkdownRemark___frontmatter___date
				order: DESC
			}
		) {
			press: edges {
				result: node {
					link: relativePath
					article: childMarkdownRemark {
						data: frontmatter {
							date(formatString: "yyyy/MM/DD")
							description
							title
							url
						}
					}
				}
			}
		}
	}
`
